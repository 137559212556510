import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Principal',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard'
  },
  {
    label: 'Viajes',
    isTitle: true
  },
  {
    label: 'Calendario',
    icon: 'calendar',
    link: '/viajes/calendario'
  },
  {
    label: 'Administrar',
    icon: 'map',
    link: '/viajes/leer'
  },
  {
    label: 'Viaje Individual',
    icon: 'map',
    link: '/viajes/crear'
  },
  {
    label: 'Programación',
    icon: 'map',
    link: '/viajes/programar'
  },
  {
    label: 'Listar Programados',
    icon: 'map',
    link: '/viajes/listProgramado'
  },
  {
    label: 'Gestión',
    isTitle: true
  },
  {
    label: 'Estado de Pago',
    icon: 'file-text',
    subItems: [
      {
        label: 'Ampliroll',
        link: '/estadoPago/listado',
      },
      {
        label: 'Carga Trasera',
        link: '/estadoPago/CT/leer',
      },
    ]
  },
  {
    label: 'Prefactura',
    icon: 'file-text',
    subItems: [
      {
        label: 'Ampliroll',
        link: '/prefactura/leer',
      },
      {
        label: 'Carga Trasera',
        link: '/prefactura/CT/leer',
      },
      {
        label: 'Buscar Prefactura',
        link: '/prefactura/buscarPrefactura'
      }
    ]
  },
  {
    label: 'Reportes',
    icon: 'pie-chart',
    subItems: [
      {
        label: 'Equipos en Cliente',
        link: '/equipo/enCliente',
      },
      {
        label: 'Equipos por Zona',
        link: '/equipo/porZona',
      },
      {
        label: 'Kilos Transportados',
        link: '/reportes/kilos-transportados',
      }
      ,
      {
        label: 'Cancelaciones',
        link: '/reportes/cancelaciones',
      },
      {
        label: 'Cancelaciones por Zona'
        , link: '/reportes/tipo-cancelaciones'
      }

      ,
      {
        label: 'Cobros',
        link: '/reportes/lista-cobros',
        admin: true
      },
      {
        label: 'Recepciones',
        link: '/reportes/recepciones',
      },
      {
        label: 'Obras',
        link: '/reportes/listadoObras',
      },
      {
        label: 'Finalizacion Recepciones',
        link: '/reportes/recepcionFinalizada',
      },
      {
        label: 'EDP Facturado',
        link: '/reportes/edpfacturado',
      },
      {
        label: 'Tiempo producción',
        link: '/reportes/tiempo-produccion',

      },
      {
        label: 'Contratos por Cliente'
        , link: '/reportes/contratos'
      },
      {
        label: 'Deuda Cliente',
        link: '/reportes/deuda-cliente'
      }
    ]
  },
  {
    label: 'Facturas de un cliente',
    icon: 'file-text',
    link: '/facturacion/por-cliente'
  },
  {
    label: 'Mantenedores',
    isTitle: true
  },
  {
    label: 'Clientes',
    icon: 'smile',
    link: '/cliente/leer'
  },
  {
    label: 'Valorización',
    icon: 'dollar-sign',
    link: '/cancelacion'
  },
  {
    label: 'Obras',
    icon: 'zap',
    link: '/obra/leer'
  },
  {
    label: 'Choferes',
    icon: 'users',
    link: '/chofer/leer'
  },
  {
    label: 'Moviles',
    icon: 'truck',
    link: '/movil/leer'
  },
  {
    label: 'Equipamiento',
    icon: 'truck',
    link: '/equipo/leer'
  },
  {
    label: 'Bases',
    icon: 'home',
    link: '/base/leer'
  },
  {
    label: 'Rellenos Sanitarios',
    icon: 'home',
    link: '/vertedero/leer'
  },
  {
    label: 'Usuarios',
    icon: 'user',
    link: '/usuario/leer',
    admin: true
  },
  {
    label: 'Cierre de Mes',
    icon: 'calendar',
    link: '/cierreMes/crear',
    admin: true
  },
  {
    label: 'Servicios',
    icon: 'zap',
    link: '/servicio/leer'
  },
  {
    label: 'Tipos Cancelacion',
    icon: 'x-circle',
    link: '/mantenedor/TipoViaje'
  },
  {
    label: 'Zonas',
    icon: 'map',
    link: '/mantenedor/zonas'
  }
];
